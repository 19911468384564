import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  ListItemText,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import MenuIcon from "@material-ui/icons/Menu";
import { BiUser } from "react-icons/bi";
import { RiWalletLine } from "react-icons/ri";
import { BsFillCaretDownFill } from "react-icons/bs";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { NavLink } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Scroll from "react-scroll";
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
// import CloseIcon from '@material-ui/icons/Close';
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    lineHeight: "21px",
    fontWeight: "600",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    color: "#222",
    padding: "0px 7px",
    letterSpacing: "1px",
    textDecoration: "none",
    marginLeft: "0",
    textDecoration: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    "@media (max-width: 990px)": {
      padding:"0px",
      width: "100%",
      display: "block",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      marginBottom: "20px",
    },
    "&.activeMenu": {
      color: "#6442f4",
    },
    "&:hover": {
      color: "#6442f4",
    },
  },
  menuButton1: {
    width: "100%",textAlign:"center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 990px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "218px",
    marginBottom: "30px",
    marginTop: "30px",
  },
  drawerContainer: {
    padding: "20px",
    height: "100%",
    background: "#000",
  overflow:"auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign:"center",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  logoBox: {
    position: "relative",
    top: 10,
    left: 0,
    // [theme.breakpoints.down("sm")]: {
    //   position: "initial",
    // },
    "@media (max-width: 990px)": {
      position: "initial",
      top:0,
    },
    "& img": {
      width: "48px",
      "@media (max-width: 990px)": {
        width: "auto",
        height:"24px",
        maxWidth:"fit-content",
      },
      // "@media (max-width: 990px)": {
      //   width: "280px",
      // },
    },
  },
  mintBtn: {
    height: "100%",
    backgroundColor: "#C8FF00",
    color: "#000",
    fontSize: "14px !important",
    fontWeight: "600",
    textTransform: "uppercase",
    boxShadow: "none",
    borderRadius: 0,
    minHeight: "48px",
    width: "120px",
   
    "@media (max-width: 990px)": {
      borderRadius:" 40px",
       height: "46px",
      marginTop: "20px",
      width:"calc(100% - 60px)",
      fontSize: "20px !important",
      maxWidth:"300px",
    },
    "&:hover": {
      backgroundColor: "#6442f4",
      color: "#fff",
    },
  },
  socailIcons: {
    paddingRight: "45px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingRight: "10px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      height: "25px",
      width: "1px",
      backgroundColor: "#ccc",
      right: "20px",
      top: "50%",
      transform: "translateY(-50%)",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  accountBox: {
    "& button": {
      color: "#6442f4",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
    },
  },
  headerbar: {
    border: "none",
    borderRadius: "0px !important",
    boxShadow: "0px 2px 15px rgb(209 209 209)",
    backgroundColor: "rgba(255,255,255, 1)",
    opacity: "1",
    "@media (max-width: 990px)": {
      backgroundColor: "#000",
      boxShadow: "none",
    },
  },
  closeButton:{
    position: "absolute",
    width: "15px",
    color: "#6adeea",
    right: "20px",
    top: "10px",
  },
}));

export default function Header() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    menuButton,
    toolbar,
    logoBox,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    submenu,closeButton,
    mintBtn,
    socailIcons,
    accountBox,
    wallet,
    headerbar,
  } = useStyles();
  const history = useHistory();
  console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 991
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const themeSeeting = useContext(SettingsContext);
  const [open, setOpen] = React.useState(false);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <Grid
          container
          style={{
            paddingLeft: "0px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          // style={{ alignItems: "center" }}
        >
          {/* <Grid item xs={1}></Grid> */}
         
        
           
         
          <Grid item xs={12} align="right">
           
         
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
               {menuLinks}
            
             <Box className="flexBetween">
             <Box pr={4} mr={3}>{mintButton}</Box> {socialIcons}
              {Profile}</Box>
            </Box>
          </Grid>

        
        </Grid>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const displayMobile = () => {
    

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
    return (
      <Toolbar className={mainHeader}>
        <Dialog fullScreen open={open} onClose={handleClose} >
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" className={closeButton}>
              <CloseIcon />
            </IconButton>
        
          {/* <IconButton  onClick={handleDrawerClose}>
          <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#6ADEEA", fontSize: "30px" }}
              />
          </IconButton> */}
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/footer_name.png" alt="" />
            {/* {getDrawerChoices()} */}
            {mintButton}
            {menuLinks}
            {MobileMenu}
          
            {socialIcons}
            <img
                      alt=""
                      src="images/logosmall.png"
                      style={{ marginTop: "10px",width:"70px" }}
                    /> 
          </div>
          </Dialog>

        <div>{mobileLogo}</div>

        <Grid container>
          <Grid item xs={12} sm={12} align="right">
          <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleClickOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#6ADEEA", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
         
        </Grid>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <Box className={logoBox}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const mobileLogo = (
    <Box className={logoBox}>
      <Link to="/">
        <img src="images/mobile-logo.png" alt="" />
      </Link>
    </Box>
  );
  const mintButton = (
    <ScrollLink smooth={true} duration={500} className={menuButton} to="mint"  onClick={() => history.push("/?id=mint")}>
      <Button variant="contained" color="primary" fullWidth className={mintBtn}>
        Mint
      </Button>
    </ScrollLink>
    
  );
  const Profile = (
    <Box className={accountBox}>
      <IconButton>
        <RiWalletLine />
      </IconButton>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <BiUser />
        <BsFillCaretDownFill style={{ fontSize: "12px" }} />
      </IconButton>
    </Box>
  );
  const socialIcons = (
    <Box className={socailIcons}>
      <IconButton>
        <a target="_blank" href="https://twitter.com/">
          <img src="images/twitter_logo.svg" />
        </a>
      </IconButton>
      <IconButton>
        <a target="_blank" href="https://discord.com/">
          <img src="images/discord_logo.svg" />
        </a>
      </IconButton>
      <IconButton>
        <a target="_blank" href="https://opensea.io/">
          <img src="images/opensea_logo.svg" />
        </a>
      </IconButton>
    </Box>
  );
  const menuLinks = (
    
    <Box align="left" className="pl24">
      {history.location.pathname !== "/" ?  <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="about"
        onClick={() => history.push("/?id=about")}
      >
        ABOUT
      </ScrollLink> : <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="about"
        
      >
        ABOUT
      </ScrollLink>}
     
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="roadmap"
        onClick={() => history.push("/?id=roadmap")}
      >
        ROADMAP
      </ScrollLink>

      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="whitepaper"
        onClick={() => history.push("/?id=whitepaper")}
      >
        WHITEPAPER
      </ScrollLink>

      <Link className={menuButton} to="/dashboard">
        {" "}
        NFT Owners Dashboard
      </Link>
    </Box>
  );
  const MobileMenu = (
    
    <Box align="center">
      
     
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="/"
        onClick={() => history.push("/")}
      >
       WALLET
      </ScrollLink>
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="/faqs"
        onClick={() => history.push("/faqs")}
      >
        F.A.Q.
      </ScrollLink>
      <ScrollLink
        smooth={true}
        duration={500}
        className={menuButton}
        to="/support"
        onClick={() => history.push("/support")}
      >
        CONTACT
      </ScrollLink>


    
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={headerbar}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        disableScrollLock={true}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile" className="profilebutton">
            My Profile
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user" className="profilebutton">
            Edit Profile
          </Link>
        </MenuItem>
        <MenuItem className="profilebutton">
          <Link to="/resell-nft" className="profilebutton">
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
