import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  IconButton,
  Hidden,
} from "@material-ui/core";
// import { useHistory, Link as RouterLink } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter } from "react-icons/fi";
import TextField from "@material-ui/core/TextField";
import {} from "react-feather";
import { Link, useHistory } from "react-router-dom";

import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: "#f30065",
    background: "#000",
    position: "relative",
    padding: "50px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#2f086a",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      marginTop: "10px",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        fontWeight: "400",
      fontSize: "16px",
      },
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("xs")]: {
        textAlign:"center",
      },
     
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(200 255 0)",
    borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
borderRadius:"50px",
height: "48px",
    },
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#c8ff00",
      minWidth: "157px",
      fontSize: "14px",
      fontWeight: "300",
      color: "#000",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#c8ff00",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "inherit",
        marginTop: "10px",    fontSize: "22px",
      },
    },
  },
  footertext: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& a": {
      color: "#fff",
      fontWeight: 700,
      fontFamily: "'Red Hat Display', sans-serif",
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "left",
      textDecoration: "none",
      textTransform: "capitalize",
      marginBottom: "10px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "15px", fontWeight:700,
      },
      "&:hover": {
        color: "#6442F4",
        textDecoration: "none",
      },
    },
  },
  socialmedia: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    "& svg": {
      fontSize: "30px",
    },
  },
  footernameBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
     },
  },
  textcenter:{
    [theme.breakpoints.down("xs")]: {
     textAlign:"center",
     marginBottom:"7px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Box
          style={{ margin: "20px 10px 0", position: "relative", zIndex: "2" }}
        >
          <Container maxWidth="lg">
            <Grid container justify="space-around" spacing={1}>
              {/* <Grid item xs={6} md={5} align="center">
                <Typography variant="h5">Find us on Social Media</Typography>
                <Box align="center" mt={3} mb={3} className="follow">
                  <img src="images/facebook-logo.png" alt="facebook" />
                  <img src="images/twitter-logo.png" alt="twitter" />
                  <img src="images/linkedin-logo.png" alt="linkedin" />
                  <img src="images/instagram-logo.png" alt="instagram" />
                </Box>
              </Grid> */}

              <Grid item xs={12} sm={8} md={7} align="left">
                <Box className={classes.footernameBox}>
                  <img alt="" src="images/footer_name.png" />
                  <Hidden xsDown>
                    <img alt="" src="images/logoIcon.png" style={{width:"80px"}} />
                  </Hidden>
                </Box>
                <Box mt={7} className={classes.textcenter}>
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    Subscribe for updates and announcements.
                  </Typography>
                </Box>

                <Box mt={1} className={classes.textFild}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Your e-mail"
                  />
                  <Button style={{ fontWeight: "600" }}>SUBSCRIBE</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1} md={2} align="left"></Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box className={classes.footertext}>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    // className={menuButton}
                    onClick={() => history.push("/?id=whitepaper")}
                    to="about"
                  >
                    About
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    style={{ scrollTop: "0" }}
                    onClick={() => history.push("/?id=whitepaper")}
                    // className={menuButton}
                    to="roadmap"
                  >
                    Roadmap
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    style={{ scrollTop: "0" }}
                    duration={500}
                    // className={menuButton}
                    to="whitepaper"
                    onClick={() => history.push("/?id=whitepaper")}
                  >
                    Whitepaper
                    {/* WHITEPAPER */}
                  </ScrollLink>
                  <Link
                    // className={menuButton}
                    to="/dashboard"
                  >
                    {" "}
                    NFT Owners Dashboard
                  </Link>
                  <Link
                    // className={menuButton}
                    to="/faqs"
                  >
                    {" "}
                    F.A.Q
                  </Link>
                  <Link
                    // className={menuButton}
                    to="/club-event"
                  >
                    {" "}
                    Club Events
                  </Link>
                  <Link
                    // className={menuButton}
                    to="/support"
                  >
                    {" "}
                    Contact Us
                  </Link>

                  {/* <List>
                    <ListItem to="/" component={RouterLink}>
                      <span> About</span>
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                      <span> Roadmap</span>
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                      <span> Whitepaper</span>
                    </ListItem>
                    <ListItem to="/dashboard" component={RouterLink}>
                      <span> NFT Owners Dashboard</span>
                    </ListItem>
                    <ListItem to="/faqs" component={RouterLink}>
                      <span> F.A.Q</span>
                    </ListItem>
                    <ListItem to="/contact" component={RouterLink}>
                      <span> Contact Us</span>
                    </ListItem>
                  </List> */}
                  <Box className={classes.socialmedia}>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://twitter.com/"
                        style={{ marginBottom: "0px", marginLeft: "-10px" }}
                      >
                        <img src="images/twitter_logo.svg" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://discord.com/"
                        style={{ marginBottom: "0px" }}
                      >
                        <img src="images/discord_logo.svg" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        target="_blank"
                        href="https://opensea.io/"
                        style={{ marginBottom: "0px" }}
                      >
                        <img src="images/opensea_logo.svg" />
                      </Link>
                    </IconButton>
                  </Box>
                  <Hidden smUp>
                  <Box mt={2}>
                    <img
                      alt=""
                      src="images/logosmall.png"
                      style={{ marginTop: "10px",width:"80px" }}
                    /> </Box> 
                     <Box className={classes.socialmedia}>
                    <p>© Metaudio 2022</p>
                    </Box> 
                  </Hidden>
                  {/**/}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
