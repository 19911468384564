export default {
  h1: {
    fontWeight: 500,
    fontSize: 42,
    fontFamily: "'Red Hat Display', sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: 34,
    fontFamily: "'Red Hat Display', sans-serif",
    "@media (max-width:991px)": {
      fontSize: 28,
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 28,
    fontFamily: "'Red Hat Display', sans-serif",
  },
  h4: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Red Hat Display', sans-serif",
  },
  h5: {
    fontWeight: 300,
    fontSize: 20,
    fontFamily: "'Red Hat Display', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Red Hat Display', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Work Sans', sans-serif",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Work Sans', sans-serif",
  },
  body1: {
    fontSize: 12,
    fontFamily: "'Work Sans', sans-serif",
  },
  body2: {
    fontWeight: 400,
    fontSize: 16,
    fontFamily: "'Work Sans', sans-serif",
    letterSpacing:"0",
    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
};
