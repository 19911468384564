import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Faq")),
  },

  {
    exact: true,
    path: "/dashboard",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Index")),
  },
  {
    exact: true,
    path: "/club-event",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Contact/Index")),
  },
  {
    exact: true,
    path: "/support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Contact/Support")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
